import { API_BASE_URL, ACCESS_TOKEN } from "../constants";
import { createBrowserHistory } from 'history';
import { IsAuthenticated } from "../app/App";

export const history = createBrowserHistory();
const request = options => {
  const headers = new Headers({
    "Content-Type": "application/json"
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append("Authorization", "Bearer " + localStorage.getItem(ACCESS_TOKEN));
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response =>
    response.json().then(json => {
      if(response.status === 401){
        localStorage.removeItem(ACCESS_TOKEN);
        IsAuthenticated(false);
         history.push("/login");
         return Promise.reject(json);
      }
      else if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser(sessionId) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + `/api/v1/sessions/${sessionId}/mediaurl`,
    method: "GET"
  });
}
