import React, { Component } from "react";
import { Link} from "react-router-dom";
import "./AppHeader.css";
import Logo from '../img/Alivecor.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class AppHeader extends Component {
  render() {
    return (
      <header className="app-header">
        <div className="container">
          <div className="app-branding">
            <Link to="/" className="app-title">
              <img alt="alivecor" src={Logo}/>
            </Link>
          </div>
          <div className="app-options">
            <nav className="app-nav">
              {this.props.authenticated  && (
                <ul>
                  <li>
                      <a role="button" onClick={this.props.onLogout}>Logout
                      <ExitToAppIcon/>
                      </a>
                  </li>
                </ul>
              )}
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default AppHeader;
