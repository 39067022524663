import React from 'react'
import {
    IconButton,
    Grid,   
    InputAdornment,
    Box,    
    TextField
  } from "@material-ui/core";
import {
    makeStyles,
    withStyles,
    useTheme
  } from "@material-ui/core/styles";
import EcgLoader from '../../common/EcgLoader/EcgLoader';
import MuiTypography from "../../common/MuiTypography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from '@material-ui/icons/Search';
import "video.js/dist/video-js.css";
import { getCurrentUser } from '../../util/APIUtils';


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      overflowX: "auto",
      marginTop: 0,
    },
    greenline:{
      borderTop:'2px solid rgba(45, 159, 134, 1)',
      width:'150px',
      display:'table',
      marginTop : '30px'
  },
  innerGrid:{
    backgroundColor:"#E2E7E3"
  },
  center: {
    textAlign:'center',
    margin:'auto'
},
    largeinput: {
      color: "#536062 !important",
      backgroundColor:"#FFFFFF",
      borderRadius:"10px",
      height:"50px",
      minWidth: "512px",
      marginBottom:'30px',
      fontFamily: "Work Sans !important",
      '&.Mui-focused': {
        border: '2px solid #2D9F86',
        backgroundColor: "transparent",
      },
      '&:hover ': {
        border: '2px solid #2D9F86',
      },  
    },
    largeinputtablet: {
      color: "#536062 !important",
      backgroundColor:"#FFFFFF",
      borderRadius:"10px",
      boxSizing:"border-box",
      height:"40px",
      minWidth: "212px",
      fontFamily: "Work Sans !important",
      '&.Mui-focused': {
        border: '2px solid #2D9F86',
        backgroundColor: "transparent",
      },
      '&:hover ': {
        border: '2px solid #2D9F86',
      },  
    },
    smallpaddediconButton: {
      padding: "5px",
    },
  }));
const CssTextField = withStyles({
    root: {
      "& input::placeholder": {
        fontFamily: "Work Sans !important",
        fontWeight: 400,
        color: "#536062 !important",
        opacity: 1,
        textAlign: "left"
      },
      '& label.Mui-focused': {
        color: '#2D9F86',
        backgroundColor: "transparent",
      },
      '& .MuiOutlinedInput-root': {      
        '&:hover fieldset': {
          borderColor: '#2D9F86',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#2D9F86',
        }
      },
    },
  })(TextField);
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

function AdminSessionInfo(props) {
    const classes = useStyles();
    const [searchInput, setSearchInput] = React.useState("");
    const [sessionUrl,setsessionUrl] = React.useState("");
    const [isrecordingAvailable,setisrecordingAvailable] = React.useState(false);
    const [message,setmessage] = React.useState("No Session Info Available");
    const mediawidth = useWidth();
    const [isLoading,setloading] = React.useState(false);
    const updateSearchInputValue = (e) => {
        setSearchInput(e.target.value);
      };
    
    const sessionHandler =()=>{
        if(searchInput.length!==0){
            setloading(true);
                getCurrentUser(searchInput)
                  .then(res => {
                    setisrecordingAvailable(res.result.recordingConsent);
                    setsessionUrl(res.result.sessionURL);
                    setmessage(res.result.message);
                    setloading(false);
                  })
                  .catch(error => {
                    setloading(false);
                    setmessage("Error Occurred. Try again");
                  });
        }
        else{
            setsessionUrl("");
            setloading(false);
            setisrecordingAvailable(false);
            setmessage("SessionId is not Valid ");
        }
        
    }
  return (
    <>
      { props.authenticated &&
        <>
          <Grid container alignItems='center' justifyContent='center' className={classes.innerGrid} direction="column">
              <Grid item>
                  <div className={classes.greenline}/>
                  <MuiTypography
                      fontSize="32px"
                      fontFamily="Work Sans"
                      letterSpacing="0.25px"
                      fontWeight="600"
                      color="#000000"
                      padding="10px 0 0 0"
                  >
                      SessionInfo
                  </MuiTypography>
              </Grid>
              <Grid item>
                <CssTextField
                placeholder={"Enter Session Id"}
                          value={searchInput}
                          onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            sessionHandler(); 
                            }
                          }}
                          onChange={(e) => updateSearchInputValue(e)}
                InputProps={{
                    className:mediawidth === "xl" || mediawidth === "lg"
                      ? classes.largeinput
                      : classes.largeinputtablet
                  ,
                  disableUnderline:true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                      classes={{
                        root:
                          mediawidth === "xl" || mediawidth === "lg"
                              ? ""
                              : classes.smallpaddediconButton,
                        }}
                        onClick={sessionHandler}
                        >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              </Grid>
          </Grid> 
          <Box mt={4}/>
          <Grid alignItems="center" container justifyContent="space-between">
                    {isrecordingAvailable && sessionUrl.length !==0 && !isLoading?
                        <video className="video-js"
                            controls
                            preload="auto"
                            controlsList="nodownload"
                            //poster="//vjs.zencdn.net/v/oceans.png"
                            style={{height:"70vh",width:"100%"}}
                        >
                            <source src={sessionUrl} type="video/mp4"></source>
                        </video>
                        :
                        isLoading ? 
                        <div className={classes.center}>
                            <EcgLoader />
                        </div>
                            
                            :
                        <Box display="flex" justify="center" alignItems="center" className={classes.center}>
                        <MuiTypography
                            fontSize="12px"
                            fontFamily="Work Sans"
                            lineHeight="20px"
                            letterSpacing="-0.2px"
                            textTransform="uppercase"
                            color="#536063"
                            textAlign="center"
                            >
                            {message} 
                        </MuiTypography>
                        </Box>
                        }
          </Grid>  
        </>     
      }
    </>
  )
}

export default AdminSessionInfo