import React, { Component } from "react";
import { Route, Switch} from "react-router-dom";
import { createBrowserHistory } from 'history';
import AppHeader from "../common/AppHeader";
import Login from "../user/login/Login";
import OAuth2RedirectHandler from "../user/oauth2/OAuth2RedirectHandler";
import NotFound from "../common/NotFound";
import { ACCESS_TOKEN } from "../constants";
import PrivateRoute from "../common/PrivateRoute";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./App.css";
import AdminSessionInfo from "../user/Admin/AdminSessionInfo";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export const history = createBrowserHistory();
export function IsAuthenticated(authenticated){
  this.setState({
    authenticated:authenticated
  })
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
    IsAuthenticated = IsAuthenticated.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false
    });
    history.push('/login');
    Alert.success("You're safely logged out!");
  }

  render() {
    return (
      <div className="app">
        <div className="app-top-box">
          {this.state.authenticated && <AppHeader authenticated={this.state.authenticated} onLogout={this.handleLogout} />}
        </div>
        <div className="app-body">
            <Switch>
              <Redirect exact from="/" to="/login"/>
              <Route
                exact
                path="/login"
                render={props => <Login authenticated={this.state.authenticated} {...props} />}
              ></Route>
              <PrivateRoute
                path="/sessioninfo"
                authenticated={this.state.authenticated}  
                component={AdminSessionInfo}
              ></PrivateRoute>
              <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
              <Route component={NotFound}></Route>
              
            </Switch>
        </div>
        <Alert stack={{ limit: 3 }} timeout={3000} position="top-right" effect="slide" offset={65} />
      </div>
    );
  }
}

export default App;
